import { Link, useLocation } from "@remix-run/react";
// import Image from 'next/image'
import { usePathname } from "next/navigation";
import styles from "./Tile.module.scss";
import React, { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";
import { useGlobalContext } from "../context/GlobalContext";
import { fixLineHeight } from "~/utils/fixLineHeight";

export default function Tile({ title, slug, imageUrl }): JSX.Element {
  const { pathname } = useLocation();
  const { locale } = useGlobalContext();

  const ColumnWrapper = ({ children, condition }): JSX.Element => {
    return condition ? (
      <div className="news-tile column is-full-mobile is-half-tablet is-half-desktop">
        {children}
      </div>
    ) : (
      <div className="news-tile column is-full-mobile is-half-tablet is-one-third-desktop">
        {children}
      </div>
    );
  };

  useEffect(() => {
    // this is extra annoying
    setTimeout(() => {
      fixLineHeight();
    }, 250);
    window.addEventListener("resize", fixLineHeight);

    // Tilt needs a proper react wrapper
    VanillaTilt.init(document.querySelectorAll(".news-tile"), {
      max: 5,
      speed: 300,
      scale: 1.035,
      glare: false,
    });
  }, []);

  return (
    <>
      <ColumnWrapper
        condition={
          pathname == "/en" ||
          pathname == "/de" ||
          pathname == "/es-es" ||
          pathname == "/es-mx" ||
          pathname == "/fr" ||
          pathname == "/it" ||
          pathname == "/pt-br"
        }
      >
        <Link to={`/${locale}/news/${slug}`}>
          <div className="card is-clickable">
            <div className="card-image">
              <figure className="image is-16by9">
                <img
                  src={imageUrl}
                  width={640}
                  height={360}
                  alt={`${title} main image`}
                  priority={true}
                />
              </figure>
            </div>
            <div className={`card-content p-3 ${styles.tileBorder}`}>
              <div className="content titlebar">
                <h3 className="title is-uppercase has-text-weight-bold my-0 is-size-5-mobile is-size-5-tablet is-size-5-desktop has-text-white">
                  {title}
                </h3>
              </div>
            </div>
          </div>
        </Link>
      </ColumnWrapper>
    </>
  );
}
